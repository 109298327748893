import * as i0 from '@angular/core';
import { Injectable, PLATFORM_ID, Directive, Inject, Optional, Input, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { create } from 'ladda';
class LaddaConfigArgs {}
class LaddaConfig {
  constructor(config = {}) {
    Object.assign(this, config);
  }
}
LaddaConfig.ɵfac = function LaddaConfig_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LaddaConfig)(i0.ɵɵinject(LaddaConfigArgs));
};
LaddaConfig.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LaddaConfig,
  factory: LaddaConfig.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LaddaConfig, [{
    type: Injectable
  }], function () {
    return [{
      type: LaddaConfigArgs
    }];
  }, null);
})();
class LaddaDirective {
  constructor(el, config, platformId) {
    this.platformId = platformId;
    this.ladda = undefined;
    this.disabled = false;
    this.el = el.nativeElement;
    if (!config) {
      return;
    }
    if (config.style && !this.el.getAttribute('data-style')) {
      this.el.setAttribute('data-style', config.style);
    }
    if (config.spinnerSize && !this.el.getAttribute('data-spinner-size')) {
      this.el.setAttribute('data-spinner-size', config.spinnerSize.toString());
    }
    if (config.spinnerColor && !this.el.getAttribute('data-spinner-color')) {
      this.el.setAttribute('data-spinner-color', config.spinnerColor);
    }
    if (config.spinnerLines && !this.el.getAttribute('data-spinner-lines')) {
      this.el.setAttribute('data-spinner-lines', config.spinnerLines.toString());
    }
  }
  ngOnChanges(changes) {
    if (!this.ladda) {
      return; // needed since ngOnChanges is called before ngOnInit
    }
    if (changes.loading) {
      this.updateLadda(changes.loading.previousValue);
    }
    if (changes.disabled) {
      this.updateDisabled();
    }
  }
  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.ladda = create(this.el);
    // if the initial loading value isn't false, a timeout of 0 ms
    // is necessary for the calculated spinner size to be correct.
    setTimeout(() => {
      this.updateLadda(false);
    }, 0);
  }
  ngOnDestroy() {
    if (this.ladda) {
      this.ladda.remove();
    }
  }
  updateLadda(previousValue) {
    if (!this.ladda) {
      return;
    }
    let loading = typeof this.loading === 'number' || !!this.loading;
    let wasLoading = typeof previousValue === 'number' || !!previousValue;
    if (!loading) {
      if (wasLoading) {
        this.ladda.stop();
      }
      return this.updateDisabled();
    }
    if (!wasLoading) {
      this.ladda.start();
    }
    if (typeof this.loading === 'number') {
      this.ladda.setProgress(this.loading);
    }
  }
  updateDisabled() {
    this.el.disabled = this.disabled;
  }
}
LaddaDirective.ɵfac = function LaddaDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LaddaDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(LaddaConfig, 8), i0.ɵɵdirectiveInject(PLATFORM_ID));
};
LaddaDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LaddaDirective,
  selectors: [["", "ladda", ""]],
  inputs: {
    loading: [0, "ladda", "loading"],
    disabled: "disabled"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LaddaDirective, [{
    type: Directive,
    args: [{
      selector: '[ladda]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: LaddaConfigArgs,
      decorators: [{
        type: Inject,
        args: [LaddaConfig]
      }, {
        type: Optional
      }]
    }, {
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, {
    loading: [{
      type: Input,
      args: ['ladda']
    }],
    disabled: [{
      type: Input
    }]
  });
})();
class LaddaModule {
  static forRoot(config) {
    return {
      ngModule: LaddaModule,
      providers: [{
        provide: LaddaConfig,
        useValue: config
      }]
    };
  }
}
LaddaModule.ɵfac = function LaddaModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LaddaModule)();
};
LaddaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LaddaModule
});
LaddaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LaddaModule, [{
    type: NgModule,
    args: [{
      declarations: [LaddaDirective],
      exports: [LaddaDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ladda
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LaddaConfig, LaddaConfigArgs, LaddaDirective, LaddaModule };

